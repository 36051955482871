import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment/moment";
import {Grid, MenuItem, Select, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from 'react';
import './meeting_info.css';
import Button from "@mui/material/Button";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import WorkingPeriod from "./working_period";


function MeetingInfo({timeInfo, saveTimeInfo}) {

    const [tempEarliestStart, setTempEarliestStart] = useState((timeInfo?.earliest_start) ?
        new Date(timeInfo.earliest_start) : new Date());

    const savedTempEarliestStart = useRef(tempEarliestStart) // remember this to trigger the timer only if tempEarliestStart has changed

    function convertToMoment(date) {
        return moment(date);
    }

    useEffect(() => {
        function setEarliestStart(newDate) {
            const earliestStartInIso = newDate.toISOString();
            console.log('meeting_info.js setEarliestStart', earliestStartInIso);
            saveTimeInfo({earliest_start: earliestStartInIso});
        }

        if (savedTempEarliestStart.current !== tempEarliestStart) {
            savedTempEarliestStart.current = tempEarliestStart; // Update the ref
            const timer = setTimeout(() => {
                setEarliestStart(tempEarliestStart); // Update earliestStart
            }, 500);

            return () => {
                clearTimeout(timer); // Clear the timer if the user types again before 500ms
            };
        }
    }, [tempEarliestStart, saveTimeInfo]); // Runs whenever tempEarliestStart changes

    function setSchedulePeriod(days) {
        saveTimeInfo({schedule_period: days})
    }

    function setDuration(minutes) {
        saveTimeInfo({duration: minutes})
    }

    return (
        <div>
            {timeInfo !== null && Object.keys(timeInfo).length !== 0 ?
                <Grid container={true} spacing={2} alignItems="center" justifyContent="left">
                    <Grid item xs={3}>
                        <Typography>Earliest Meeting Start</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <div className="datePicker">
                                    <DatePicker
                                        label="Earliest Meeting Start"
                                        value={convertToMoment(tempEarliestStart)}
                                        onChange={(newValue) => {
                                            if (newValue && moment.isMoment(newValue)) {
                                                setTempEarliestStart(newValue.toDate());
                                            }
                                        }}
                                        ampm={false} // Use 24-hour format
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Duration</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            labelId="duration-label"
                            id="duration"
                            value={timeInfo.duration}
                            label="Maximum Number of Days"
                            onChange={(event) => setDuration(event.target.value)}
                            variant="standard"
                        >
                            <MenuItem value={30}>30 minutes</MenuItem>
                            <MenuItem value={60}>one hour </MenuItem>
                            <MenuItem value={90}>90 minutes</MenuItem>
                            <MenuItem value={120}>two hours</MenuItem>
                            <MenuItem value={180}>three hours</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography pr={5}> Schedule Period</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={timeInfo.schedule_period}
                            label="Maximum Number of Days"
                            onChange={(event) => setSchedulePeriod(event.target.value)}
                            variant="standard"
                        >
                            <MenuItem value={7}>One Week</MenuItem>
                            <MenuItem value={14}>Two Weeks</MenuItem>
                            <MenuItem value={21}>Three Weeks</MenuItem>
                        </Select>
                    </Grid>
                    <WorkingPeriod information={timeInfo} saveInformation={saveTimeInfo}/>
                    <Grid item xs={2}>
                        <Button> Generate</Button>
                    </Grid>
                </Grid>
                : "loading ...."}
        </div>
    );
}

export default MeetingInfo;