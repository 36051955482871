import React, {useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Container, Typography, TextField, Button, Grid } from '@mui/material';
import {directusClient} from "../utils/directus";
import {AuthContext} from "../authorization_state";
import ErrorModal from "../utils/error_modal";
import Cookies from "js-cookie";
import {readMe} from "@directus/sdk";

export default function LoginPage() {
    const navigate = useNavigate();
    const {setUser} = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginState, setLoginState] = useState('start');
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);


    const handleLogin = async () => {
        try {
            const response = await directusClient.login(email, password);
            const expiresIn = response.expires; // assuming this is in milliseconds
            // const expirationDate = new Date();
            // expirationDate.setMilliseconds(expirationDate.getMilliseconds() + expiresIn);
            // setTokenExpiryDate(expirationDate);
            // setTokenExpiryPeriod(response.expires/1000*900);
            const tokenExpirationDate = new Date(response.expires_at);
            console.log('Token will expire at: ', tokenExpirationDate);
            const first20Chars = response.refresh_token.substring(0, 20);
            console.log("auth.js processLogin setting new refresh token: " + first20Chars);
            Cookies.set('refreshToken', response.refresh_token, {
                expires: 7, // expires in 7 days
                secure: true, // for HTTPS
                sameSite: 'strict', // CSRF protection
            });

            directusClient.request(readMe({ fields: ['*.*'] })).then(user => {
                user.refreshToken = response.refresh_token;
                setUser(user);
                console.log('auth.js processLogin succeeded, refresh token:', user.refreshToken.substring(0, 20));
                return Promise.resolve('Authorization succeeded');
            }).catch(error => {
                console.log('auth.js processLogin failed to read user:', error);
                return Promise.reject('Authorization failed');
            })
        } catch (error) {
            console.error('login_page.js handleLogin error:', error);
            throw error;
        }
    }

    const loginClicked = () => {
        handleLogin().then(() => {
                navigate('/Profile');
            }).catch(error => {
                setErrorMessage('An error occurred logging in: ' + error.message);
                setLoginState('error');
            });
    }


    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
        setErrorMessage(null);
    };

    return (
        <div>
            <ErrorModal
                isOpen={isErrorModalOpen}
                message={errorMessage}
                onClose={handleCloseErrorModal}
            />
        <Container component="main" maxWidth="xs" >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '32px' }}>
                { loginState === "error" ?
                    <Typography component="h1" variant="h5"> Try again: wrong email or password</Typography> :
                <Typography component="h1" variant="h5"> Sign in </Typography> }
                <form noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={loginClicked}
                    >
                        Sign In
                    </Button>
                    <Grid container pt={1} pb={1}>
                        <Grid item xs>
                            <Link to="/forgot_password">Reset Password</Link>
                        </Grid>
                        <Grid item>
                            <Link to="/register">Register</Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
        </div>
    );
}
