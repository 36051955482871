import {Grid, Switch, Typography} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {TimeField} from "@mui/x-date-pickers/TimeField";
import {AuthContext} from "../authorization_state";

function WorkingPeriod({information, saveInformation}) {
    const [workDayStarts, setWorkDayStarts] = useState(convertTimeToMoment(information.workday_starts));
    const [workDayEnds, setWorkDayEnds] = useState(convertTimeToMoment(information.workday_ends));
    const [lunchStarts, setLunchStarts] = useState(convertTimeToMoment(information.lunch_starts));
    const [lunchEnds, setLunchEnds] = useState(convertTimeToMoment(information.lunch_ends));

    const savedWorkDayStarts = useRef(workDayStarts);
    const savedWorkDayEnds = useRef(workDayEnds);
    const savedLunchStarts = useRef(lunchStarts);
    const savedLunchEnds = useRef(lunchEnds);

    function setSkipLunch(value) {
        if (value)
            saveInformation({skip_lunch: "yes"});
        else
            saveInformation({skip_lunch: "no"});
    }

    function convertTimeToMoment(timeString) {
        // check if timeString is in the format HH:mm
        try {
            const [hours, minutes] = timeString.split(":");
            return moment().hours(hours).minutes(minutes) }
        catch (error) {
            console.log("error trying to convert: ", timeString, error);
            return moment()
        }
    }

    function TimeSelector({
                              value, setValue, label,
                              minValue = convertTimeToMoment("00:00"),
                              maxValue = convertTimeToMoment("23:59")
                          }) {
        const {setMessage} = useContext(AuthContext);
        // all types are moment objects
        // TimeField is a controlled component, so we need to use a ref to store the current value
        const savedMoment = useRef(value);
        const [currentMoment, setCurrentMoment] = useState(value);

        useEffect(() => {
            function setMoment(newMoment) {
                console.log('meeting_info.js set time', newMoment);
                if (currentMoment.isSameOrAfter(minValue) &&
                    currentMoment.isSameOrBefore(maxValue)) {
                    // the database wants a string in the format HH:mm
                    setValue(newMoment);
                } else {
                    setMessage("Time must be between " + minValue.format("HH:mm") + " and "
                        + maxValue.format("HH:mm"));
                }
            }

            if (savedMoment.current !== currentMoment) {
                savedMoment.current = currentMoment; // Update the ref
                const timer = setTimeout(() => {
                    setMoment(currentMoment); // Update the value of the parent
                }, 500);

                return () => {
                    clearTimeout(timer); // Clear the timer if the user types again before 500ms
                };

            }
        }, [currentMoment, maxValue, minValue, setMessage, setValue]); // Runs whenever currentMoment changes

        return <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className="timePicker">
                <TimeField
                    defaultValue={currentMoment}
                    label={label}
                    value={currentMoment}
                    ampm={false}
                    minTime={minValue}
                    maxTime={maxValue}
                    format="HH:mm" // Set the format to 24-hour
                    onBlur={(e) => {
                        setCurrentMoment(convertTimeToMoment(e.target.value));
                    }}

                />
            </div>
        </LocalizationProvider>;
    }

    // avoid calling this useEffect when workDayStarts is set the first time to the initial value

    useEffect(() => {
        if (savedWorkDayStarts.current !== workDayStarts) {
            savedWorkDayStarts.current = workDayStarts;
            saveInformation({workday_starts: workDayStarts.format("HH:mm")});
            if (lunchStarts.isAfter(workDayStarts)) {
                setLunchStarts(workDayStarts.clone());
            }
        }
    }, [saveInformation,workDayStarts, lunchStarts]);

    useEffect(() => {
        if (savedWorkDayEnds.current !== workDayEnds) {
            savedWorkDayEnds.current = workDayEnds;
            saveInformation({workday_ends: workDayEnds.format("HH:mm")});
            if (lunchEnds.isAfter(workDayEnds)) {
                setLunchEnds(workDayEnds.clone());
            }
        }
    }, [lunchEnds, workDayEnds, saveInformation]);

    useEffect(() => {
        if (savedLunchStarts.current !== lunchStarts) {
            savedLunchStarts.current = lunchStarts;
            saveInformation({lunch_starts: lunchStarts.format("HH:mm")});
        }
    }, [lunchStarts, saveInformation]);

    useEffect(() => {
        if (savedLunchEnds.current !== lunchEnds) {
            savedLunchEnds.current = lunchEnds;
            saveInformation({lunch_ends: lunchEnds.format("HH:mm")});
            }
    }, [lunchEnds, saveInformation]);

    function addMinutes(momentTime, minutes) {
        return momentTime.clone().add(minutes, 'minutes');
    }

    return (
        <React.Fragment>
            <Grid item xs={3}>
                <Typography pr={5}> Work Day Starts</Typography>
            </Grid>
            <Grid item xs={3}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {TimeSelector(
                        {
                            value: workDayStarts,
                            setValue: setWorkDayStarts, label: "Day Starts",
                            maxValue: addMinutes(workDayEnds, -60)
                        })}
                </div>
            </Grid>
            <Grid item xs={3}>
                <Typography pr={5}> Work Day Ends
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {TimeSelector({
                        value: workDayEnds,
                        setValue: setWorkDayEnds, label: "Day Ends",
                        minValue: addMinutes(workDayStarts, 60),
                    })}
                </div>
            </Grid>
            <Grid item xs={3}>
                <Typography pr={5}> Skip Lunch</Typography>
            </Grid>
            <Grid item xs={9}>
                <Switch
                    checked={information.skip_lunch === "yes"}
                    onChange={(event) => setSkipLunch(event.target.checked)}
                />
            </Grid>
            {information.skip_lunch === "yes" ?
                <React.Fragment>
                    <Grid item xs={3}>
                        <Typography> Lunch Starts</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        {TimeSelector(
                            {
                                value: lunchStarts,
                                setValue: setLunchStarts, label: "Lunch Starts",
                                minValue: addMinutes(workDayStarts, 0),
                                maxValue: addMinutes(lunchEnds, -10)
                            })}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Lunch Ends
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {TimeSelector(
                            {
                                value: lunchEnds,
                                setValue: setLunchEnds, label: "Lunch Ends",
                                minValue: addMinutes(lunchStarts, 10),
                                maxValue: addMinutes(workDayEnds, -10)
                            })}
                    </Grid>
                </React.Fragment> : null}
        </React.Fragment>
    )
}

export default WorkingPeriod;