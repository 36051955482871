import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment-timezone'
import {useEffect, useState} from "react";
import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import useDirectusRequest from "../utils/directus";
import {updateMe} from "@directus/sdk";
import * as Sentry from '@sentry/react';

const localizer = momentLocalizer(moment)
// Helper function to convert a day and time to a moment object
function convertDayTimeToMoment(day, timeString) {
    const [hours, minutes] = timeString.split(":");
    return moment().day(day).hours(hours).minutes(minutes);
}

const setLunchTimeSlots = (user, currentEventsList ) => {
    // if (eventsList.length === 0) return;
    const noLunchTimeSlots = currentEventsList.filter(event => event.title !== 'Lunch');

    if (user) {
        if (user.skip_lunch === "yes") {
            const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            // Loop over the days array
            const lunchEvents = days.map(day => {
                // Create a new event for each day
                return {
                    user: user.id,
                    start: convertDayTimeToMoment(day, user.lunch_starts).toDate(),
                    end: convertDayTimeToMoment(day, user.lunch_ends).toDate(),
                    title: 'Lunch',
                    mode: 'Lunch'
                };
            });
            // console.log("Lunchtime Slots: ", lunchEvents)
            // console.log("Other Slots: ", noLunchTimeSlots)
            // Merge the lunch events with the existing events
            return [
                ...noLunchTimeSlots,
                ...lunchEvents
            ]
        } else {
            return noLunchTimeSlots;
        }
    }
}

const MeetingCalendar = ({user, initialEventsList}) => {
    // MeetingCalendar component
    // can be used to display and edit a user's calendar
    // user: the user object
    // can be used to display the meeting slots while scheduling a meeting
    // user: meeting

    const request = useDirectusRequest();
    const [eventsList, setEventsList] = useState([]);
    const [savedEventsList, setSavedEventsList] = useState([]); // New state variable for savedEventsList
    const [eventsChanged, setEventsChanged] = useState(false);
    const [mode, setMode] = useState('Blocked'); // New state variable for mode
    // Get the earliest meeting date from the user object, or set a default value
    const earliestMeetingDate = user.earliest_start || new Date();
    // Use moment to get the start of the week for the earliest meeting date
    const startOfWeek = moment(earliestMeetingDate).startOf('week').toDate();
    const [date, setDate] = useState(startOfWeek);

    //
    // useEffect(() => {
    //     const updatedTimeSlots = setLunchTimeSlots(user, eventsList)
    //     setSavedEventsList(updatedTimeSlots);
    //     setEventsList(updatedTimeSlots)
    //     // LunchTime slots are always in the savedEventsList so that
    //     // they are not written to the database
    //     setEventsChanged(false)
    // }, [user]); // eventsList is not a dependency because only the elements affected by user change

    useEffect(() => {
        function compareLists(list1,list2) {
            if (list1.length !== list2.length) return false;
            for (let i = 0; i < list1.length; i++) {
                if (list1[i].start.getTime() !== list2[i].start.getTime() ||
                    list1[i].end.getTime() !== list2[i].end.getTime() ||
                    list1[i].title !== list2[i].title) {
                    return false;
                }
            }
            console.log("List are the same")
            return true;
        }
        debugger;
        if (initialEventsList) {
            console.log("Compare:", compareLists(eventsList,savedEventsList))
            setEventsList(initialEventsList);
            setSavedEventsList(initialEventsList);
            const updatedTimeSlots = setLunchTimeSlots(user, initialEventsList)
            setSavedEventsList(updatedTimeSlots);
            setEventsList(updatedTimeSlots)
            // LunchTime slots are always in the savedEventsList so that
            // they are not written to the database
            setEventsChanged(false)
            console.log("meeting-calendar initial eventsList: ", eventsList)
        }
    }, [user, initialEventsList]);

    // useEffect(() => { console.log("Events Changed: ", eventsList) }, [eventsList])

    const saveAction = async () => {
        if (eventsChanged) {
            const newData = {
                time_slots: {
                    create: eventsList.filter(event =>
                        // the event is not already saved
                        !savedEventsList.some(savedEvent =>
                            savedEvent.start.getTime() === event.start.getTime() &&
                            savedEvent.end.getTime() === event.end.getTime() &&
                            savedEvent.title === event.title)
                    ),
                    delete: savedEventsList.filter(savedEvent =>
                        !eventsList.some(event =>
                            event.start.getTime() === savedEvent.start.getTime() &&
                            event.end.getTime() === savedEvent.end.getTime() &&
                            event.title === savedEvent.title
                        )
                    ).map(event => event.id)
                }
            }
            try {
                console.log("Saving new Events:", newData)
                await request(updateMe(newData));
                setSavedEventsList(eventsList);
                setEventsChanged(false)
            } catch (error) {
                console.error('Meeting Calendar Saving Error:', error);
                Sentry.captureException(error)
            }
        }
    }

    const discardAction = () => {
        setEventsList(savedEventsList)
        setEventsChanged(false)
    }

    const createEvent = ({start, end}) => {
        const title = mode;
        setEventsChanged(true)
        setEventsList([
            ...eventsList,
            {
                user: user.id,
                start,
                end,
                title,
                mode
            },
        ])
    }

    const deleteEvent = (selectedEvent) => {
        // you cannot delete calendar or lunch events
        if (selectedEvent.title !== "calendar" && selectedEvent.title !== "Lunch") {
            setEventsList(eventsList.filter(event =>
                !(event.start.getTime() === selectedEvent.start.getTime() &&
                    event.end.getTime() === selectedEvent.end.getTime() &&
                    event.title === selectedEvent.title)
            ));
            setEventsChanged(true)
        }
    }

    const colorMap = {
        "Blocked": "grey",
        "Permanently Blocked": "red",
        "Preferred Time": "green",
        "Lunch": "blue"
    }

    const eventStyleGetter = (event) => {
        var backgroundColor = colorMap[event.mode];
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }

    const handleChange = (event) => {
        setMode(event.target.value);
    };

    function convertTimeToMoment(timeString) {
        const [hours, minutes] = timeString.split(":");
        return moment().hours(hours).minutes(minutes);
    }


    function workDayStarts(user)
    {
        const start = (user?.workday_starts) ? convertTimeToMoment(user.workday_starts) : convertTimeToMoment("09:00");
        const end = (user?.workday_ends) ? convertTimeToMoment(user.workday_ends) : convertTimeToMoment("17:00");

        if (start >= end) {
            return convertTimeToMoment("09:00");
        }
        return start;

    }

    function workDayEnds(user)
    {
        const start = (user?.workday_starts) ? convertTimeToMoment(user.workday_starts) : convertTimeToMoment("09:00");
        const end = (user?.workday_ends) ? convertTimeToMoment(user.workday_ends) : convertTimeToMoment("17:00");

        if (start >= end) {
            return convertTimeToMoment("17:00");
        }
        return end;
    }

    return (
        <Box>
            {eventsChanged ? <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography pr={2}>Don't forget to save your data before leaving this page</Typography>
                </Grid>
            </Grid> : null}

            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography pr={2}>Select Entry Type</Typography>
                </Grid>
                <Grid item>
                    <Select
                        value={mode}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{'aria-label': 'Without label'}}
                        variant="standard">
                        <MenuItem value="" disabled>
                            Select mode
                        </MenuItem>
                        <MenuItem value={"Blocked"}>Block</MenuItem>
                        <MenuItem value={"Permanently Blocked"}>Block Always</MenuItem>
                        <MenuItem value={"Preferred Time"}>Whitelist</MenuItem>
                    </Select>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={saveAction}>
                        Save
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={discardAction}>
                        Discard
                    </Button>
                </Grid>
            </Grid>
            <Box pt={5} pb={5}>
                <Calendar
                    selectable
                    localizer={localizer}
                    events={eventsList}
                    startAccessor="start"
                    endAccessor="end"
                    // views={{work_week: true}}
                    workWeekStartDay={0}
                    workWeekEndDay={5}
                    style={{height: 500}}
                    onSelectSlot={createEvent}
                    onSelectEvent={deleteEvent}
                    eventPropGetter={eventStyleGetter}
                    min={workDayStarts(user)} // Set start time
                    max={workDayEnds(user)} //  Set end time
                    defaultView='week' // add this line
                    date={date} // Set the first day to the start of the week of the earliest meeting date
                    onNavigate={newDate => setDate(newDate)}
                    views={[Views.WEEK]}
                />
            </Box>
        </Box>
    )
}

export default MeetingCalendar;