import {
    Autocomplete,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import useDirectusRequest, {directusClient} from "../utils/directus";
import {
    createItem,
    createUser,
    deleteItem,
    readUser,
    readUsers,
    updateItem,
    updateMe,
    updateUser
} from "@directus/sdk";
import { v4 as uuidv4 } from 'uuid';
import {useContext, useEffect, useState} from "react";
import extractEmails from "../utils/check_email";
import {AuthContext} from "../authorization_state";

function Attendees({meeting, attendees, addAttendee, deleteAttendee}) {
    const request = useDirectusRequest();
    const [knownUsers, setKnownUsers] = useState([]);
    const {setMessage} = useContext(AuthContext)

    async function makeUser(email) {
            return directusClient.request(createUser( {
                email: email,
                first_name: "Undefined",
                last_name: "Undefined",
                status: "invited",
                password: "hidden"
            })).then(user => {
                // generate a uuid
                const uuid = uuidv4();
                return request(createItem('invited_users',
                    {
                        email: email,
                        user_id: user.id,
                        validation_key: uuid,
                    })).then(response => {
                    return Promise.resolve(user);
                })
            }).catch(error => {
                return Promise.reject(error);
            })
    }
    async function checkUser(checkEmail) {
        try {
            const user_list = await directusClient.request(readUsers({
                filter: {
                    email: {
                        _eq: checkEmail,
                    }
                }
            }));
            console.log('meeting.js checkUser', user_list);
            return Promise.resolve(user_list);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function saveNewAttendee(newAttendeeUser){
        // newAttendeeUser is of type user with a full user record
        try {
            let attendee_item = await request(createItem('attendee', {
                    meeting_id: meeting.id,
                    user_id: newAttendeeUser.id,
                    status: newAttendeeUser.status,
                    type: "standard"
                }))

            attendee_item.user_info = newAttendeeUser
            attendee_item.email = newAttendeeUser.email;
            attendee_item.first_name = newAttendeeUser.first_name;
            attendee_item.last_name = newAttendeeUser.last_name;

            // the attendees are of type meeting_user with id, with user_id, email and name
            const newAttendeesList = addAttendee(attendee_item);
            const new_id_list = newAttendeesList.map(attendee => attendee.id);
            await request(updateItem('meeting',meeting.id,
                    {attendees: new_id_list}))
            return Promise.resolve('Attendee: ' + newAttendeeUser.email + ' added');
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function addNewUser(user){
        try
        {
            const message = await saveNewAttendee(user);
            setMessage(message);
            // make the users colleagues
            await request(updateMe({
                colleagues: {
                    "create": [{
                        directus_users_id: meeting.owner_id.id,
                        related_directus_users_id: user.id
                    }]
                    }
            }))

            await request(updateUser(user.id,{
                colleagues: {
                    "create": [{
                        directus_users_id: user.id,
                        related_directus_users_id: meeting.owner_id.id
                    }]
                }
            }))
            setKnownUsers([...knownUsers, user]);
            return Promise.resolve(message);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    useEffect(() => {
    console.log('attendees.js: knownUsers state updated:', knownUsers);
}, [knownUsers]);

    const addNewAttendee = (newAttendee) => {
        if (!newAttendee) {
            return;
        }
        // check newAttendee is an object or a string
        if (typeof newAttendee === 'string') {
            const emails = extractEmails(newAttendee);
            const new_attendees= emails.map(email => {
                return checkUser(email).then(user_list => {
                    let new_user = null;
                    if (user_list.length === 0) {
                        return makeUser(email).then(user => {
                            return addNewUser(user)
                        })
                    } else
                    {
                        return addNewUser(user_list[0])
                    }
                }).catch(error => {
                    console.log('meeting.js saveNewAttendee error:', error);
                })
            })
        }
        else { // it is an object
            const emailExists = attendees.some(attendee => attendee.email === newAttendee.email);
            if (emailExists) {
                setMessage('Email already exists in the attendees list');
            } else {
                newAttendee.status = "added"
                saveNewAttendee(newAttendee).then(message => {
                    setMessage(message);
                }).catch(error => {
                    console.log('meeting.js saveNewAttendee error:', error);
                })
            }
        }
    }

    async function getColleagues(owner_id) {
        try {
            const user = await request(readUser(owner_id, {
                fields: ["colleagues.*.*"]
            }));

            return user.colleagues.map(
                colleague => {
                    return colleague.related_directus_users_id;
                }
            )
        } catch(error) {
            return Promise.reject(error);
        }
    }

    const deleteSelectedAttendee = async (id) => {
        const newAttendees = deleteAttendee(id)
        const new_id_list = newAttendees.map(attendee => attendee.id);
        await request(deleteItem('attendee',id))
        await request(updateItem('meeting',meeting.id,
            {attendees: new_id_list})).then(() => {
            setMessage('Attendee deleted');
            return Promise.resolve('success');
        }).catch(error => {
            setMessage('Error deleting attendee from meeting' + error)
            console.log('meeting.js deleteAttendee error:', error);
        })
    }

    useEffect(() => {
        getColleagues(meeting.owner_id.id).then(colleagues => {
            //setMessage("Read known colleagues")
            console.log("Read colleagues")
            setKnownUsers(colleagues)
        }).catch(error => {
            console.log('Attendees.js getColleagues error:', error);
        })
    },[meeting.owner_id.colleagues])

    return (
        <div>
            <Autocomplete
                disablePortal
                freeSolo={true}
                id="combo-box-demo"
                options={knownUsers}
                getOptionLabel={(option) => `${option.first_name} ${option.last_name} ${option.email}`}
                sx={{ width: 500 }}
                onChange={(event, value) => addNewAttendee(value)}
                renderInput={(params) => <TextField {...params} label="Name/Email" />}
            />

            {attendees && attendees.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {attendees.map((attendee) => (
                                <TableRow key={attendee.id}>
                                    <TableCell>{attendee.first_name}</TableCell>
                                    <TableCell>{attendee.last_name}</TableCell>
                                    <TableCell>{attendee.email}</TableCell>
                                    <TableCell>{attendee.type}</TableCell>
                                    <TableCell>{attendee.status}</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" onClick={() => deleteSelectedAttendee(attendee.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <p>None</p>
            )}
        </div>
    );
}

export default Attendees;