import {useCallback, useContext, useEffect, useState} from "react";
import {updateMe} from "@directus/sdk";
import {Box, Typography, Grid} from "@mui/material";
import MeetingCalendar from "../meeting/meeting_calendar";
import WorkingPeriod from "../meeting/working_period";
import {AuthContext} from "../authorization_state";
import readWebCalendars from "../utils/readWebCalendars";
import directusClient from "../utils/directus";

function Availability() {
    const {user, setUser} = useContext(AuthContext);
    const [timeSlots, setTimeSlots] = useState([]); // New state variable for timeSlots

   const saveUser = useCallback( async (newProps) => {
        const updatedUser = {...user, ...newProps};
        setUser(updatedUser);
        try{
            await directusClient.request(updateMe(newProps));
        }
        catch (error) {
            console.error('An error occurred:', error);
        }
    }, [user, setUser]);

    function getLastWeekStartAndNextWeekEnd() {
        const currentDate = new Date();
        const currentDayOfWeek = currentDate.getDay();
        const currentWeekStartDate = new Date(currentDate);
        currentWeekStartDate.setDate(currentDate.getDate() - currentDayOfWeek);
        console.log("currentWeekStartDate",currentWeekStartDate)
        const lastWeekStartDate = new Date(currentWeekStartDate);
        lastWeekStartDate.setDate(currentWeekStartDate.getDate() - 7);

        const nextWeekEndDate = new Date(currentWeekStartDate);
        nextWeekEndDate.setDate(currentWeekStartDate.getDate() + 13);

        const lastWeekStartStr = lastWeekStartDate.toISOString().split('T')[0];
        const nextWeekEndStr = nextWeekEndDate.toISOString().split('T')[0];

        return { lastWeekStartStr, nextWeekEndStr };
    }
    
    useEffect(() => {
        // loadEvents().then(time_slots => {
        const fetchData = async () => {
            if (user === null) return;
            const timeSlotsWithMomentStart = user.time_slots.map(slot => ({
                ...slot, // needed to get the ids of the slots for deletion
                start: new Date(slot.start),
                end: new Date(slot.end),
                title: 'blocked'
            }))
            // Usage
            const {lastWeekStartStr, nextWeekEndStr} = getLastWeekStartAndNextWeekEnd();
            console.log(`Start of the last week: ${lastWeekStartStr}`);
            console.log(`End of the next week: ${nextWeekEndStr}`);
            const blockedTimeSlots = await readWebCalendars(user, lastWeekStartStr, nextWeekEndStr);
            console.log("blockedTimeSlots", blockedTimeSlots)

            return [...timeSlotsWithMomentStart, ...blockedTimeSlots];
        }

        console.log("Availability useEffect User:  ", user)
        if (user)
            fetchData().then(newTimeSlots => {
                console.log("NT",newTimeSlots)
                setTimeSlots(newTimeSlots)
            })
    },[user]);

    return (
        user !== null  ?
        <Box pr={5} pl={5}>
           <Typography pt={3} variant="h2" textAlign="center" pb={4}> Time Preferences</Typography>

            <Grid container={true} spacing={2} alignItems="center" justifyContent="left">
               <WorkingPeriod information={user} saveInformation={saveUser}/>
            </Grid>
            <Box pt={5}>
                <MeetingCalendar user={user} initialEventsList={timeSlots} />
            </Box>

        </Box> : "loading ...."
    )
}

export default Availability;