import {useContext, useEffect, useState, useCallback} from "react";
import {useParams} from 'react-router-dom';
import {readItem, readItems, updateItem} from "@directus/sdk";
import {directusClient} from "../utils/directus";
import MeetingCalendar from "./meeting_calendar";
import Attendees from "./attendees";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import EditableTextField from "./editable_textfield";
import MeetingInfo from "./meeting_info";
import {AuthContext} from "../authorization_state";


const AccordionComponent = (ChildComponent, props) => {
    return function AccordionInstance() {
        const {isAccordionOpen, setIsAccordionOpen} = props;
        const handleAccordionToggle = (event, newIsAccordionOpen) => {
            setIsAccordionOpen(newIsAccordionOpen);
        };

        return (
            <Accordion expanded={isAccordionOpen} onChange={handleAccordionToggle}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h4" align="center" paddingTop={2}>{props.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ChildComponent {...props}/>
                </AccordionDetails>
            </Accordion>
        )
    }
}

async function getTimeSlots(time_slots) {
    if (time_slots === undefined) throw new Error('getTimeSlots: time_slots is undefined');
    if (time_slots.length === 0) return [];

    return await directusClient.request(readItems('time_slot', {
            filter: {
                id: {
                    "_in": time_slots
                }
            }
        }
    )).then(time_slots => {
        return Promise.resolve(time_slots);
    }).catch(error => {
        return Promise.reject(error);
    })
}

function translatedTimeSlot(event, startDate, endDate) {
    const originalStart = new Date(event.start);
    const originalEnd = new Date(event.end);
    const originalWeekday = originalStart.getDay();
    const originalDuration = originalEnd - originalStart;
    const originalStartOfDay = new Date(originalStart);
    originalStartOfDay.setHours(0, 0, 0, 0);

    const new_time_slots = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        if (d.getDay() === originalWeekday) {
            const newEventStart = new Date(d);
            newEventStart.setHours(originalStart.getHours(), originalStart.getMinutes(), originalStart.getSeconds(), originalStart.getMilliseconds());
            const newEventEnd = new Date(newEventStart.getTime() + originalDuration);
            new_time_slots.push({start: newEventStart, end: newEventEnd, title: "attendee"});
        }
    }
    console.log('meeting.js translatedTimeSlot:', event, new_time_slots)
    return new_time_slots;
}

function addAttendeeMeetingSlots(meeting, newAttendee) {
    const meeting_period_end = new Date(meeting.earliest_start);
    meeting_period_end.setDate(meeting_period_end.getDate() + meeting.schedule_period);
    // check that newAttendee has user_info ?
    if (!('user_info' in newAttendee)) throw new Error('addAttendeeMeetingslot: no user_info')
    return getTimeSlots(newAttendee.user_info.time_slots).then(attendee_time_slots => {
        const permanently_blocked = attendee_time_slots.filter(event => (event.mode === "Permanently Blocked" || event.mode === "Lunch"));
        return permanently_blocked.map(event => {
            return translatedTimeSlot(event, new Date(meeting.earliest_start), meeting_period_end)
        }).flat();
    }).catch(error => {
        console.log('meeting.js addAttendee: getEvents error:', error);
        throw (error)
    })
}


function Meeting() {
    const {setMessage, user} = useContext(AuthContext)
    const [firstLoad, setFirstLoad] = useState(true);
    const {id} = useParams();
    const [meeting, setMeeting] = useState({});
    const [attendees, setAttendees] = useState([])
    const [isAttendeesAccordionOpen, setIsAttendeesAccordionOpen] = useState(false);
    const [isTimeInfoAccordionOpen, setIsTimeInfoAccordionOpen] = useState(false);
    const [meetingTimes, setMeetingTimes] = useState([]);

    const buildMeetingTimes = useCallback((meeting, attendees) => {
        const newEventsPromises = attendees.map(attendee => addAttendeeMeetingSlots(meeting, attendee)).flat()
        const allEvents = Promise.all(newEventsPromises).then(events => {
            return events.flat();
        })
        console.log("buildMeetingTimes:", allEvents)
        return allEvents;
    }, [])

    const saveMeeting = useCallback( async (newProps)  => {
        const newMeeting = {...meeting, ...newProps};
        try {
            await directusClient.request(updateItem('meeting', meeting.id, newProps))
            await buildMeetingTimes(meeting, attendees)
            setMessage('Meeting updated');
            setMeeting(newMeeting);
        } catch (error) {
            return Promise.reject(error);
        }
    }, [meeting, attendees, setMessage, buildMeetingTimes])

    function addAttendee(newAttendee) {
        const newAttendees = [...attendees, newAttendee];
        setAttendees(newAttendees)
        addAttendeeMeetingSlots(meeting, newAttendee).then(newEvents => {
            setMeetingTimes([...meetingTimes, ...newEvents])
        }).catch(error => {
            console.log('meeting.js addAttendee error:', error);
        })
        return newAttendees
    }

    function deleteAttendee(id) {
        const newAttendees = attendees.filter(a => a.id !== id);
        setAttendees(newAttendees);
        buildMeetingTimes(meeting, newAttendees).then(events => {
            setMeetingTimes(events);
        }).catch(error => {
                setMessage('Error building meeting times' + error.message)
            }
        )
        return newAttendees;
    }

    const AccordionAttendees = AccordionComponent(Attendees,
        {
            meeting: meeting,
            attendees: attendees,
            addAttendee: addAttendee,
            deleteAttendee: deleteAttendee,
            isAccordionOpen: isAttendeesAccordionOpen,
            setIsAccordionOpen: setIsAttendeesAccordionOpen,
            title: "Attendees"
        });

    const AccordionMeetingInfo = AccordionComponent(MeetingInfo,
        {
            timeInfo: meeting,
            saveTimeInfo: saveMeeting,
            isAccordionOpen: isTimeInfoAccordionOpen,
            setIsAccordionOpen: setIsTimeInfoAccordionOpen,
            title: "Meeting Info"
        });


    function saveTitle(newTitle) {
        if (newTitle !== meeting.title)
            setMeeting({...meeting, title: newTitle});
        saveMeeting({title: newTitle});
    }

    useEffect(() => {
        async function getMeetingInfo(meetingId) {
            try {
                const meeting = await directusClient.request(readItem('meeting', meetingId,
                    {
                        fields: ["*.*.*"],
                    }
                ));
                setMeeting(meeting);
                const attendees = meeting.attendees.map(attendee => {
                    // enrich the attendee with the user info
                    if (!attendee.user_id)
                        throw Error("Illegal Attendee Info for " + attendee.id)
                    return {
                        ...attendee,
                        email: attendee.user_id.email,
                        first_name: attendee.user_id.first_name,
                        last_name: attendee.user_id.last_name,
                        user_info: attendee.user_id
                    }
                });
                setAttendees(attendees);
                return Promise.resolve({meeting, attendees});
            } catch (error) {
                return Promise.reject(error);
            }
        }

        if (user && firstLoad)
            getMeetingInfo(id)
                .then(meeting_info => {
                    const {meeting, attendees} = meeting_info;
                    buildMeetingTimes(meeting, attendees)
                        .then(events => {
                            setMeetingTimes(events);
                            console.log("all events:", events)
                            setFirstLoad(false);
                        })
                        .catch(error => {
                            setMessage("Error building meeting times" + error.message)
                        });
                })
                .catch(error => {
                    setMessage('Error loading meeting: ' + error.message)
                });
    }, [id, setMessage, buildMeetingTimes, user, firstLoad]);

    return (
        <Box paddingTop={10} paddingLeft={20} paddingRight={20}>
            {Object.keys(meeting).length !== 0 ? (
                <div>
                    <EditableTextField input_text={meeting.title} save_title={saveTitle} variant="h2" align="center"/>
                    <AccordionAttendees/>
                    <AccordionMeetingInfo/>

                    <Box paddingTop={10}>
                        {meetingTimes &&
                            <MeetingCalendar user={meeting}
                                             initialEventsList={meetingTimes}
                            />}
                    </Box>
                </div>
            ) : (<p>Loading...</p>)}
        </Box>
    );
}

export default Meeting;