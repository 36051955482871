import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import {AuthContext} from "../authorization_state";
import {IconButton, Menu, MenuItem} from "@mui/material";


export default function NavBar() {
    const {isLoggedIn} = useContext(AuthContext);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleMenuToggle = (event) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    }

    const handleMenuItemClick = () => {
        setAnchorEl(null);
    };

    const open = !!anchorEl


    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };
        window.addEventListener('resize', updateScreenSize);
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    const linkTable = [
        {label: "Home", link: "/", condition: true},
        {label: "Login", link: "/login", condition: !isLoggedIn},
        {label: "Availability", link: "/availability", condition: isLoggedIn},
        {label: "Organizer", link: "/meeting_list", condition: isLoggedIn},
        {label: "Attendee", link: "/meeting_list", condition: isLoggedIn},
        {label: "Faq", link: "/faq", condition: true},
        {label: "Test", link: "/test", condition: isLoggedIn},
        {label: "Profile", link: "/profile", condition: isLoggedIn},
    ];

    return (
        <AppBar position="static" mb={2}>
            <Toolbar>
                {isSmallScreen ? (
                    <>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle}>
                            <MenuIcon/>
                        </IconButton>
                        {open && (
                            <Menu anchorEl={anchorEl}
                                  id="account-menu"
                                  open={open}
                                  onClose={handleMenuItemClick}
                                  onClick={handleMenuItemClick}
                            >
                                {linkTable.map((link) => {
                                    return link.condition ?
                                        <MenuItem key={link.label} color="inherit" onClick={handleMenuItemClick}
                                                  component={Link} to={link.link}>{link.label}</MenuItem> : null;
                                })}
                            </Menu>
                        )}
                        <span className="label">Navigation</span>

                    </>
                ) : (
                    <>
                        {linkTable.map((link, index) => {
                            return link.condition ? <Button key={index} color="inherit" component={Link}
                                                            to={link.link}>{link.label}</Button> : null;
                        })}
                    </>)
                }
            </Toolbar>
        </AppBar>
    );
}
