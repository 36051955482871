import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Typography, Stack, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import {readItem, updateItem, createItem} from '@directus/sdk';
import {directusClient} from "../utils/directus";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Button from "@mui/material/Button";


function AttendeeSelection() {
    const {meetingId, userId} = useParams();
    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date());
    const [date, setDate] = useState(new Date());
    const [meetingSlots, setMeetingSlots] = useState([]);
    const totalSlots = meetingSlots.length;
    const [unknownSlots, setUnknownSlots] = useState(totalSlots);
    const [tableMode, setTableMode] = useState(false);
    const [meetingTitle, setMeetingTitle] = useState("");
    const [owner, setOwner] = useState("");

    async function updateUserChoice(choiceId, meetingSlotId, status) {
        console.log("updating:", choiceId, meetingSlotId, status);
        if (choiceId) {
            try {
                await directusClient.request(updateItem('meeting_user_choice', choiceId, {
                    choice: status,
                }));
                return choiceId;
            } catch (error) {
                return Promise.reject(`Error updating user choice: ${(error).message}`);
            }
        } else {
            try {
                const item = await directusClient.request(createItem('meeting_user_choice', {
                    user_id: userId,
                    meeting_slot_id: meetingSlotId,
                    choice: status,
                }));
                return item.id;
            } catch (error) {

                return Promise.reject(`Error updating user choice: ${(error).message}`);
            }
        }
    }

    useEffect(() => {
        async function getMeetingInfo(meetingId) {
            try {
                const meeting = await directusClient.request(readItem('meeting', meetingId, {
                    fields: ["*.*.*"],
                }));
                setMeetingTitle(meeting.title);
                if (meeting.owner_id)
                    setOwner(meeting.owner_id.first_name + " " + meeting.owner_id.last_name);

                const newMeetingSlots = meeting.meeting_slots.map(slot => {
                    const userChoices = slot.meeting_user_choice.filter(choice => choice.user_id === userId);
                    const user_choice = (userChoices.length !== 0) ? userChoices[0] : null;
                    const startDate = moment(slot.start, "YYYY-MM-DD HH:mm:ss");
                    const endDate = startDate.clone().add(meeting.duration, "minutes");
                    return {
                        start: startDate.toDate(),
                        end: endDate.toDate(),
                        status: user_choice ? user_choice.choice : "unknown",
                        slotId: slot.id,
                        choiceId: user_choice ? user_choice.id : null,
                    };
                });

                // compute the earliest starting time and the latest ending time,cover at least the time
                // between 9:00 - 17:00
                const earliestMeetingHour = Math.min(...newMeetingSlots.map(slot => slot.start.getHours()));
                const earliestMeetingMinutes = Math.min(...newMeetingSlots.map(slot => slot.start.getMinutes()));
                const earliestMeetingTime = moment().hours(earliestMeetingHour).minutes(0)
                if (earliestMeetingHour > 9)
                    setMinTime(moment().hours(9).minutes(0).toDate());
                else
                    setMinTime(earliestMeetingTime.toDate());
                const latestMeetingTime = earliestMeetingTime.clone().add(earliestMeetingMinutes + meeting.duration, "minutes");
                // check if latestMeetingTime is after midnight
                if (earliestMeetingTime.isSame(latestMeetingTime, "day")) {
                    if (latestMeetingTime.isBefore(moment().hours(17).minutes(0)))
                        setMaxTime(moment().hours(17).minutes(0).toDate());
                    else
                        setMaxTime(latestMeetingTime.toDate());
                } else
                    setMaxTime(moment().hours(23).minutes(59).toDate());

                const earliestMeetingDate = Math.min(...newMeetingSlots.map(slot => slot.start.getTime()));
                const startOfWeek = moment(earliestMeetingDate).startOf('week').toDate();
                setDate(startOfWeek);
                const unknownSlots = newMeetingSlots.filter(slot => slot.status === "unknown").length;
                setUnknownSlots(unknownSlots);
                setMeetingSlots(newMeetingSlots);
            } catch (error) {
                console.error('Error loading meeting:', error.message);
            }
        }

        if (meetingId) {
            getMeetingInfo(meetingId);
        }
    }, [meetingId, userId]);

    async function onChangeEvent(event) {
        const newMeetingSlotPromises = meetingSlots.map(async (slot) => {
            if (slot.start.getTime() === event.start.getTime() && slot.end.getTime() === event.end.getTime()) {
                // change the status of the event to the next status
                const statusMap = {
                    "unknown": "busy",
                    "free": "busy",
                    "busy": "free",
                };
                const newStatus = statusMap[event.status];
                try {
                    const newId = await updateUserChoice(slot.choiceId, slot.slotId, newStatus);
                    return {...slot, choiceId: newId, status: newStatus};
                } catch (error) {
                    console.error("Error updating user choice:", error);
                    return slot;
                }
            }
            return slot;
        });
        const newMeetingSlots = await Promise.all(newMeetingSlotPromises);
        // count all the meetingSlots that are unknown
        const unknownSlots = newMeetingSlots.filter(slot => slot.status === "unknown").length;
        setUnknownSlots(unknownSlots);
        setMeetingSlots(newMeetingSlots);
    }

    const colorMap = {
        "unknown": "grey",
        "busy": "red",
        "free": "green",
    };

    const eventStyleGetter = (event) => {
        const style = {
            backgroundColor: colorMap[event.status] || "grey",
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {style};
    };

    const localizer = momentLocalizer(moment);

    return (
        <Box paddingTop={10} paddingLeft={20} paddingRight={20}>

            <Stack>
                <Typography variant="h3" textAlign="center" pb={4}> Select Your Availability</Typography>
                {meetingTitle && <Typography variant="h4" textAlign="center" pb={4}> for Meeting: {meetingTitle} by {owner} </Typography>}
                <Button
                    style={{alignSelf: 'flex-start', fontSize: 'inherit'}}
                    variant={"contained"}
                    onClick={() => setTableMode(!tableMode)}>
                    {tableMode ? "Show Calendar" : "Show Table"}
                </Button>

                {(unknownSlots === 0) ? <Typography variant="body1" color="green" textAlign="center">
                        You are done! Thank you for selecting your availability</Typography> :
                    <>
                        <Typography variant="body1" color="red" textAlign="center"> You have {unknownSlots} slots left to
                            select. </Typography>
                        <Typography variant="body1" color="red" textAlign="center"> Click on a slot to change your
                            availability. </Typography>
                    </>
                }

                {!tableMode ? (
                    <Stack>
                        {meetingSlots.length ? (
                            <Box pt={5} pb={5}>
                                <Calendar
                                    selectable
                                    localizer={localizer}
                                    events={meetingSlots}
                                    startAccessor="start"
                                    endAccessor="end"
                                    views={['week']}
                                    workWeekStartDay={1}
                                    workWeekEndDay={5}
                                    style={{height: 500}}
                                    onSelectEvent={onChangeEvent}
                                    eventPropGetter={eventStyleGetter}
                                    min={minTime}
                                    max={maxTime}
                                    defaultView='week'
                                    date={date}
                                    onNavigate={newDate => setDate(newDate)}
                                />
                            </Box>
                        ) : (<p>Loading...</p>)}
                    </Stack>
                ) : (  meetingSlots.length &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {meetingSlots.map(slot => (
                                    <TableRow key={slot.slotId}>
                                        <TableCell>{slot.start.toLocaleString()}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color={slot.status === "free" ? "success" : slot.status === "busy" ? "error" : "grey" }
                                                onClick={() => onChangeEvent(slot)}
                                            >
                                                {slot.status}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                )}
            </Stack>
        </Box>
    );
}

export default AttendeeSelection;